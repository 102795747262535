<template>
  <v-container>
    <p class="text-right mt-2 mr-2 text-xl-body-1">
      {{ $t('components.priceDetails.netTotal') }}: {{ formatCurrency(totalSum) }}
    </p>
    <MyDataTable
      :headers="headers"
      :data="statistics"
      class="elevation-1"
      :sort-by="['total']"
      :sort-desc="[true]"
      :items-per-page="300"
      locale="pl"
      :no-data-text="$t('components.myDataTable.noDataAvailable')"
      :loading="false"
      :length="0"
      :page="0"
    >
    </MyDataTable>
  </v-container>
</template>

<script>
import { selectedLanguageComputed } from '@/store/helper';
import { formatCurrency } from '../../../mixins/formatCurrency';

export default {
  components: {
    MyDataTable: () => import('@/components/MyDataTable.vue'),
  },
  mixins: [formatCurrency],
  props: {
    statistics: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...selectedLanguageComputed,
    headers() {
      return [
        { text: this.$t('components.statistics.category'), value: 'category' },
        { text: this.$t('components.statistics.total'), value: 'total' },
      ];
    },
    totalSum() {
      return this.statistics.reduce((acc, curr) => acc + curr.total, 0);
    },
  },
};
</script>
