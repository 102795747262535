<template>
  <v-select
    :items="months"
    item-text="name"
    item-value="number"
    v-model="selectedMonth"
    return-object
    solo
    outlined
    :label="$t('months.select')"
    @change="emitMonth"
  />
</template>

<script>
export default {
  name: 'MonthSelectComponent',
  data() {
    return {
      selectedMonth: null,
    };
  },
  emits: ['update:month'],
  computed: {
    months() {
      return [
        { number: 1, name: this.$i18n.t('months.january') },
        { number: 2, name: this.$i18n.t('months.february') },
        { number: 3, name: this.$i18n.t('months.march') },
        { number: 4, name: this.$i18n.t('months.april') },
        { number: 5, name: this.$i18n.t('months.may') },
        { number: 6, name: this.$i18n.t('months.june') },
        { number: 7, name: this.$i18n.t('months.july') },
        { number: 8, name: this.$i18n.t('months.august') },
        { number: 9, name: this.$i18n.t('months.september') },
        { number: 10, name: this.$i18n.t('months.october') },
        { number: 11, name: this.$i18n.t('months.november') },
        { number: 12, name: this.$i18n.t('months.december') },
      ];
    },
  },
  methods: {
    emitMonth() {
      this.$emit('update:month', this.selectedMonth.number);
    },
  },
  created() {
    this.selectedMonth = this.months.find((month) => month.number === new Date().getMonth() + 1);
  },
};
</script>
