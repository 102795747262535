import config from '../../configuration.json';
import axios from 'axios';

const url = `${config.VUE_APP_API_URL}/statistics`;

axios.defaults.withCredentials = true;

class StatisticsService {
  static async getYearlyStatisticsForSysadmin({ year, referralCode }) {
    const res = await axios.get(`${url}/orders/year`, {
      params: {
        year: year,
        referralCode: referralCode,
      },
    });
    return res.data;
  }

  static async getMonthlyStatisticsForSysadmin({ year, month, referralCode }) {
    const res = await axios.get(`${url}/orders/month`, {
      params: {
        year: year,
        month: month,
        referralCode: referralCode,
      },
    });
    return res.data;
  }

  static async getMonthlyStatisticsForSalesman({ year, month, referralCode }) {
    const res = await axios.get(`${url}/orders/salesman/month`, {
      params: {
        year: year,
        month: month,
        referralCode: referralCode,
      },
    });
    return res.data;
  }

  static async getYearlyStatisticsForSalesman({ year, referralCode }) {
    const res = await axios.get(`${url}/orders/salesman/year`, {
      params: {
        year: year,
        referralCode: referralCode,
      },
    });
    return res.data;
  }
}

export default StatisticsService;
