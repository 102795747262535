<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <YearSelectComponent @update:year="handleYearUpdate" :start="year" />
      </v-col>
      <v-col cols="6">
        <SalesmanSelectComponent @update:salesman="handleSalesmanUpdate" />
      </v-col>
    </v-row>

    <StatisticsTable :statistics="statistics" />
    <ExportStatisticsToExcel :data="statistics" :salesman="selectedSalesman" :year="year" />
  </v-container>
</template>
<script>
import YearSelectComponent from './components/SelectYear.vue';
import StatisticsTable from '@/components/statistics/components/StatisticsTable.vue';
import StatisticsService from '@/services/StatisticsService';
import SalesmanSelectComponent from '@/components/statistics/components/SelectSalesman.vue';
import ExportStatisticsToExcel from '@/components/statistics/components/ExportStatisticsToExcel.vue';

export default {
  name: 'YearlyOrderStatistics',
  components: {
    ExportStatisticsToExcel,
    SalesmanSelectComponent,
    StatisticsTable,
    YearSelectComponent,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      selectedSalesman: null,
      statistics: [],
    };
  },
  methods: {
    handleYearUpdate(year) {
      this.year = year;
      this.fetchStatistics();
    },

    handleSalesmanUpdate(selectedSalesman) {
      this.selectedSalesman = selectedSalesman;
      this.fetchStatistics();
    },

    async fetchStatistics() {
      try {
        const response = await StatisticsService.getYearlyStatisticsForSysadmin({
          year: this.year,
          referralCode: this.selectedSalesman?.referralCode,
        });
        this.statistics = response.data.statistics;
      } catch (e) {
        console.error(e);
      }
    },
  },

  async created() {
    await this.fetchStatistics();
  },
};
</script>
<style lang="scss" scoped></style>
