<template>
  <v-select
    :items="salesmen"
    item-text="name"
    item-value="referralCode"
    v-model="selectedSalesman"
    return-object
    clearable
    solo
    outlined
    :label="$t('components.salesman.select')"
    @input="emitSalesman"
  />
</template>

<script>
import SalesmanService from '@/services/SalesmanService';

export default {
  name: 'SalesmanSelectComponent',
  emits: ['update:salesman'],
  data() {
    return {
      selectedSalesman: null,
      salesmen: [],
    };
  },
  methods: {
    async fetchSalesman() {
      try {
        const response = await SalesmanService.getAllTraders();
        this.salesmen = response.data.map((salesman) => ({
          name: `${salesman.firstName} ${salesman.lastName}`,
          referralCode: salesman.salesmanCode,
        }));
      } catch (e) {
        console.error(e);
      }
    },
    emitSalesman() {
      if (!this.selectedSalesman) {
        this.$emit('update:salesman', null);
        return;
      }

      this.$emit('update:salesman', this.selectedSalesman);
    },
  },
  async created() {
    await this.fetchSalesman();
  },
};
</script>
