<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <YearSelectComponent @update:year="handleYearUpdate" :start="year" />
      </v-col>
    </v-row>

    <StatisticsTable :statistics="statistics" />
    <ExportStatisticsToExcel :data="statistics" :year="year" />
  </v-container>
</template>
<script>
import YearSelectComponent from './components/SelectYear.vue';
import StatisticsTable from '@/components/statistics/components/StatisticsTable.vue';
import StatisticsService from '@/services/StatisticsService';
import ExportStatisticsToExcel from '@/components/statistics/components/ExportStatisticsToExcel.vue';

export default {
  name: 'YearlyOrderStatistics',
  components: {
    ExportStatisticsToExcel,
    StatisticsTable,
    YearSelectComponent,
  },

  data() {
    return {
      year: new Date().getFullYear(),
      statistics: [],
    };
  },

  methods: {
    handleYearUpdate(year) {
      this.year = year;
      this.fetchStatistics();
    },

    async fetchStatistics() {
      try {
        const response = await StatisticsService.getYearlyStatisticsForSalesman({
          year: this.year,
          referralCode: this.referralCode,
        });
        this.statistics = response.data.statistics;
      } catch (e) {
        console.error(e);
      }
    },
  },

  async created() {
    await this.fetchStatistics();
  },
};
</script>
<style lang="scss" scoped></style>
