<template>
  <export-to-excel-button :data="statisticsForExport" :filename="getExportFilename()" />
</template>

<script>
import ExportToExcelButton from '@/components/ExportToExcelButton.vue';
import { formatCurrency } from '@/mixins/formatCurrency';

export default {
  name: 'ExportStatisticsToExcel',
  components: { ExportToExcelButton },
  mixins: [formatCurrency],
  props: {
    data: {
      type: Array,
      required: true,
    },
    salesman: {
      type: Object,
      required: false,
    },
    year: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedMonth: null,
    };
  },
  computed: {
    statisticsForExport() {
      return this.data
        ?.map((statistic) => {
          const translatedCategory = this.$t(`statics.categoriesList.${statistic.category}`);
          return {
            category: translatedCategory,
            total: this.formatCurrency(statistic.total),
          };
        })
        .sort((a, b) => a.category.localeCompare(b.category));
    },
  },

  methods: {
    getExportFilename() {
      if (this.salesman) {
        return `statistics-${this.salesman.name}-${this.year}.xlsx`;
      }

      return `statistics-${this.year}.xlsx`;
    },
  },
};
</script>
