<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <YearSelectComponent @update:year="handleYearUpdate" :start="year" />
      </v-col>
      <v-col cols="4">
        <MonthSelectComponent @update:month="handleMonthUpdate" />
      </v-col>
    </v-row>

    <StatisticsTable :statistics="statistics" />

    <ExportStatisticsToExcel :data="statistics" :year="year" />
  </v-container>
</template>
<script>
import StatisticsTable from '@/components/statistics/components/StatisticsTable.vue';
import YearSelectComponent from '@/components/statistics/components/SelectYear.vue';
import MonthSelectComponent from '@/components/statistics/components/SelectMonth.vue';

import StatisticsService from '@/services/StatisticsService';
import ExportStatisticsToExcel from '@/components/statistics/components/ExportStatisticsToExcel.vue';

export default {
  name: 'MonthlyOrderStatistics',
  components: {
    ExportStatisticsToExcel,
    StatisticsTable,
    YearSelectComponent,
    MonthSelectComponent,
  },

  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      statistics: [],
    };
  },

  methods: {
    async handleYearUpdate(year) {
      this.year = year;
      await this.fetchStatistics();
    },

    async handleMonthUpdate(month) {
      this.month = month;
      await this.fetchStatistics();
    },

    async fetchStatistics() {
      try {
        const response = await StatisticsService.getMonthlyStatisticsForSalesman({
          year: this.year,
          month: this.month,
        });
        this.statistics = response.data.statistics;
      } catch (e) {
        console.error(e);
      }
    },
  },

  async created() {
    await this.fetchStatistics();
  },
};
</script>
<style lang="scss" scoped></style>
