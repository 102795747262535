<template>
  <PrimaryButton :onClick="downloadExcel">{{
    text || $t('components.exportToExcel')
  }}</PrimaryButton>
</template>

<script>
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default {
  components: { PrimaryButton },
  props: {
    data: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
    },
    filename: {
      type: String,
      default: 'data.xlsx',
    },
  },
  methods: {
    downloadExcel() {
      if (this.data && Array.isArray(this.data)) {
        let ws = XLSX.utils.json_to_sheet(this.data);
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data');
        let wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        saveAs(new Blob([wbout], { type: 'application/octet-stream' }), this.filename);
      }
    },
  },
};
</script>
