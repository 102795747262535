<template>
  <title-wrapper :title="$t('components.statistics.title')">
    <v-tabs v-model="tab" class="mt-8">
      <v-tab href="#tab-yearly">{{ $t('components.tabs.yearly') }}</v-tab>
      <v-tab href="#tab-monthly">{{ $t('components.tabs.monthly') }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-yearly">
        <v-card-text>
          <YearlyOrderStatistics />
        </v-card-text>
      </v-tab-item>

      <v-tab-item value="tab-monthly">
        <v-card-text>
          <MonthlyOrderStatistics />
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </title-wrapper>
</template>
<script>
import YearlyOrderStatistics from '@/components/statistics/YearlyOrderStatisticsSalesman.vue';
import MonthlyOrderStatistics from '@/components/statistics/MonthlyOrderStatisticsSalesman.vue';
import TitleWrapper from '@/components/TitleWrapper.vue';

export default {
  name: 'SysAdminStatistics',
  components: { MonthlyOrderStatistics, YearlyOrderStatistics, TitleWrapper },
  data() {
    return {
      tab: null,
    };
  },
};
</script>
<style lang="scss" scoped></style>
