<template>
  <v-select
    v-model="selectedYear"
    :items="years"
    solo
    :label="$t('years.select')"
    outlined
    @input="emitYear"
  ></v-select>
</template>

<script>
export default {
  name: 'YearSelectComponent',
  emits: ['update:year'],
  props: {
    start: {
      type: Number,
      default: new Date().getFullYear(),
    },
  },

  data() {
    return {
      selectedYear: new Date().getFullYear(),
    };
  },
  computed: {
    years() {
      const currentYear = new Date().getFullYear();
      const years = [];

      for (let year = currentYear; year >= 2022; year--) {
        years.push(year);
      }

      return years;
    },
  },
  methods: {
    emitYear() {
      this.$emit('update:year', this.selectedYear);
    },
  },

  created() {
    this.selectedYear = this.start;
  },
};
</script>
